﻿var C = require('src/caboodal');
var $ = window.jQuery;

/* Automatically focus the first suitable input control on the page */
function autofocus() {
    var container;
    var target;

    if ($(':focus').length) {
        return;
    }

    container = $('.page-content');
    target = $('[data-autofocus="true"]', container);

    if (!target.length) {
        target = $(
            'input:focusable, textarea:focusable, select:focusable',
            container
        ).not('.datepicker input, .timepicker input');
    }

    target.first().focus();
}

/* Perform auto-focus as the last step in page initialization */
C.ready(autofocus, 99);

﻿(function loader(root) {
    'use strict';

    /* global jQuery, _ */

    if (!root.Caboodal) {
        root.Caboodal = {};
    }

    (function module(Caboodal, $, _) {
        var queue = [];
        var loaded = false;

        $(function ready() {
            loaded = true;

            _.each(_.sortBy(queue, 'priority'), function each(item) {
                item.operation($);
            });
        });

        // Queues an operation to be performed when the document has finished loading, with optional execution priority
        Caboodal.ready = function ready(operation, priority) {
            if (loaded) {
                operation($);
                return;
            }

            queue.push({
                operation: operation,
                priority: +priority || 2
            });
        };
    }(root.Caboodal, jQuery, _));
}(window));
